import { Injectable, inject } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationFacade {
  toast = inject(ToastrService);

  show(message: string, title?: string, config?: IndividualConfig) {
    return this.toast.show(message, title, config);
  }

  success(message: string, title?: string, config?: IndividualConfig) {
    return this.toast.success(message, title, config);
  }

  error(message: string, title?: string, config?: IndividualConfig) {
    return this.toast.error(message, title, config);
  }

  info(message: string, title?: string, config?: IndividualConfig) {
    return this.toast.info(message, title, config);
  }

  warning(message: string, title?: string, config?: IndividualConfig) {
    return this.toast.warning(message, title, config);
  }

  clear() {
    return this.toast.clear();
  }
}
